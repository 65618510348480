import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Typography, Container, Button, TextField, InputLabel, MenuItem, FormControl, Select, Input } from '@mui/material';
import axios from 'axios';
import Drawer from '../components/Drawer';
import AppBar from '../components/AppBar';
import EducationSystem from '../components/EducationSystem';
import PopularPrograms from '../components/PopularPrograms';
import AdmissionRequirements from '../components/AdmissionRequirements';
import CostOfLiving from '../components/CostOfLiving';
import VisaInformation from '../components/VisaInformation';
import { fetchEducationSystems } from '../components/EducationSystem';
import { fetchPopularPrograms } from '../components/PopularPrograms';
import { fetchAdmissionRequirements } from '../components/AdmissionRequirements';
import { fetchCostOfLiving } from '../components/CostOfLiving';
import { fetchVisaInformation } from '../components/VisaInformation';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function ManageDetails() {
  const [value, setValue] = useState(0);
  const [countries, setCountries] = useState([]);


  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/locations`);
        setCountries(response.data);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };
    fetchCountries();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <div>
      <Drawer />
      <AppBar />
      <Container maxWidth="md">
        <Box sx={{ width: '100%', mt: 15 }}>
          <Tabs value={value} onChange={handleChange} aria-label="details tabs">
            <Tab label="Education System" />
            <Tab label="Popular Programs" />
            <Tab label="Admission Requirements" />
            <Tab label="Cost of Living" />
            <Tab label="Visa Information" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <EducationSystem />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PopularPrograms />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AdmissionRequirements />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <CostOfLiving />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <VisaInformation />
          </TabPanel>
        </Box>
      </Container>
    </div>
  );
}

export default ManageDetails;
