import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faMicroscope, 
  faPaintBrush,
  faBriefcase,
  faBalanceScale,
  faLanguage,
  faFlask
} from '@fortawesome/free-solid-svg-icons';
import { useCountry } from '../context/CountryContext';
import { getFieldIcon } from '../utils/helpers';
import { useTranslation } from 'react-i18next';

const PopularFields = () => {
  const { country } = useCountry();
  const { t } = useTranslation();

  return (
    <div style={{
      padding: '40px 20px',
      maxWidth: '1200px',
      margin: '50px auto',
      backgroundColor: '#f8f9fa'
    }}>
      <h2 style={{
        fontSize: '2.5em',
        textAlign: 'center',
        marginBottom: '40px',
        color: '#1C6BD3',
        position: 'relative'
      }}>
        {t('popularFields')} in {country.name}
      </h2>
      
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '15px',
        justifyContent: 'center',
        padding: '20px'
      }}>
        {country.programs?.fields?.map((field, index) => (
          <div key={index} style={{
            backgroundColor: '#fff',
            padding: '15px 25px',
            borderRadius: '30px',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            transition: 'transform 0.2s ease',
            ':hover': {
              transform: 'translateY(-3px)'
            }
          }}>
            <FontAwesomeIcon icon={getFieldIcon(field)} style={{ color: '#1C6BD3' }} />
            <span style={{ fontWeight: '600', color: '#2c3e50' }}>{field}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PopularFields;
