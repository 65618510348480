import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  Button, 
  useMediaQuery, 
  useTheme 
} from '@mui/material';
import { 
  School as SchoolIcon, 
  EmojiPeople as EmojiPeopleIcon, 
  Language as LanguageIcon 
} from '@mui/icons-material';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import ErrorBoundary from './ErrorBoundary';

const StudyInCanada = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const studyHighlights = [
    {
      icon: <SchoolIcon sx={{ fontSize: 50, color: '#1C6BD3' }} />,
      title: '3,000+ Students',
      description: 'Have successfully studied abroad with us.',
      color: '#1C6BD3'
    },
    {
      icon: <EmojiPeopleIcon sx={{ fontSize: 50, color: '#6A11CB' }} />,
      title: 'Expert Guidance',
      description: 'Our experts guide you every step of the way.',
      color: '#6A11CB'
    }
  ];

  return (
    <ErrorBoundary>
      <Box 
        sx={{ 
          background: 'linear-gradient(135deg, #f5f7fa 0%, #f5f7fa 100%)', 
          py: { xs: 4, md: 8 },
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <Container maxWidth="lg">
          {/* Header Section */}
          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <Typography 
              variant="h3" 
              sx={{ 
                fontWeight: 700, 
                color: '#1C6BD3', 
                mb: 2,
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                flexWrap: 'wrap',
                gap: 2
              }}
            >
              <LanguageIcon sx={{ fontSize: 40, color: '#1C6BD3' }} />
              {t('welcomeMessage')} & {t('achieveDreams')}
            </Typography>
            
            <Typography 
              variant="h6" 
              sx={{ 
                color: '#666', 
                maxWidth: 800, 
                margin: '0 auto', 
                lineHeight: 1.6 
              }}
            >
              {t('launchJourney')} It can be intimidating, but we're here for you! 
              Follow in the steps of 3,000+ other students and trust our experts to guide your way.
            </Typography>
          </Box>

          {/* Highlights Grid */}
          <Grid 
            container 
            spacing={4} 
            justifyContent="center" 
            alignItems="stretch"
          >
            {studyHighlights.map((highlight, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <motion.div
                  whileHover={{ 
                    scale: 1.05,
                    boxShadow: '0 15px 30px rgba(0,0,0,0.1)'
                  }}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ 
                    duration: 0.5, 
                    delay: index * 0.2 
                  }}
                >
                  <Card 
                    sx={{ 
                      height: '100%', 
                      display: 'flex', 
                      flexDirection: 'column', 
                      alignItems: 'center', 
                      p: 3,
                      borderRadius: 3,
                      textAlign: 'center',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-10px)',
                        boxShadow: '0 15px 30px rgba(0,0,0,0.1)'
                      }
                    }}
                  >
                    <Box 
                      sx={{ 
                        width: 100, 
                        height: 100, 
                        borderRadius: '50%', 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center',
                        background: `linear-gradient(135deg, ${highlight.color}, ${highlight.color}80)`,
                        mb: 2,
                        boxShadow: `0 10px 20px ${highlight.color}40`
                      }}
                    >
                      {highlight.icon}
                    </Box>

                    <Typography 
                      variant="h5" 
                      sx={{ 
                        fontWeight: 600, 
                        mb: 1,
                        color: '#333'
                      }}
                    >
                      {highlight.title}
                    </Typography>
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        color: '#666', 
                        flex: 1 
                      }}
                    >
                      {highlight.description}
                    </Typography>
                  </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>

          {/* Call to Action */}
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              mt: 4 
            }}
          >
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <Button
                variant="contained"
                size="large"
                sx={{
                  background: 'linear-gradient(45deg, #1C6BD3, #6A11CB)',
                  color: 'white',
                  px: 4,
                  py: 2,
                  borderRadius: 3,
                  fontSize: '1rem',
                  fontWeight: 600,
                  boxShadow: '0 10px 20px rgba(28, 107, 211, 0.3)'
                }}
              >
                Get Personalized Study Abroad Options
              </Button>
            </motion.div>
          </Box>
        </Container>
      </Box>
    </ErrorBoundary>
  );
};

export default StudyInCanada;
