import React from 'react';
import { Box, Grid, Typography, IconButton } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ backgroundColor: '#1976d2', color: 'white', padding: '30px 20px' }}>
      <Grid container spacing={3}>
        {/* Column 1: About Us */}
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" sx={{ marginBottom: '15px' }}>
            {t('aboutUs')}
          </Typography>
          <Typography variant="body2">
            {t('aboutUsDescription')}
          </Typography>
        </Grid>

        {/* Column 2: Contact Info */}
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" sx={{ marginBottom: '15px' }}>
            {t('contactUs')}
          </Typography>
          <Typography variant="body2">+250 788 447 599</Typography>
          <Typography variant="body2">+250 788 447 599</Typography>
          <Typography variant="body2">info@earthlinkgroup.com</Typography>
        </Grid>

        {/* Column 3: Follow Us */}
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" sx={{ marginBottom: '15px' }}>
            {t('followUs')}
          </Typography>
          <IconButton color="inherit" href="https://www.linkedin.com">
            <LinkedInIcon />
          </IconButton>
          <IconButton color="inherit" href="https://www.facebook.com">
            <FacebookIcon />
          </IconButton>
          <IconButton color="inherit" href="https://www.twitter.com">
            <TwitterIcon />
          </IconButton>
        </Grid>
      </Grid>
      
      {/* Copyright Section */}
      <Box textAlign="center" sx={{ marginTop: '20px' }}>
        <Typography variant="body2">
          &copy; {new Date().getFullYear()} {t('earthlinkBusinessCenter')}. {t('allRightsReserved')}.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
