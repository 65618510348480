import { createContext, useContext, useState } from 'react';
import axios from "axios";
import { getFieldIcon, getStepIcon, getStepDescription } from '../utils/helpers';

const defaultCountry = {
  name: 'Canada',
  code: 'CA',
  benefits: [],
  programs: { top: [], fields: [] },
  visaSteps: []
};

export const CountryContext = createContext();

export const CountryProvider = ({ children }) => {
  const [country, setCountry] = useState(defaultCountry);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const fetchCountryDetails = async (countryId) => {
    try {
      const response = await axios.get(`/api/countries/${countryId}`);
      setCountry(response.data);
    } catch (error) {
      console.error('Error fetching country details:', error);
    }
  };

  return (
    <CountryContext.Provider value={{ country, setCountry, fetchCountryDetails }}>
      {children}
    </CountryContext.Provider>
  );
};
export const useCountry = () => useContext(CountryContext);

const COUNTRIES = {
  CA: { name: 'Canada', code: 'CA' },
  US: { name: 'United States', code: 'US' },
  RW: { name: 'Rwanda', code: 'RW' }
};

export const fetchCountryDetails = async (countryCode) => {
  const country = COUNTRIES[countryCode];
  if (!country) {
    throw new Error(`Country not found: ${countryCode}`);
  }
  return country;
};
