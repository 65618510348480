import React, { useEffect, useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import WebIcon from '@mui/icons-material/Web';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import SectionIcon from '@mui/icons-material/ViewModule';
import PeopleIcon from '@mui/icons-material/People';
import GroupIcon from '@mui/icons-material/Group';
import SuggestionIcon from '@mui/icons-material/Feedback';
import InfoIcon from '@mui/icons-material/Info';
import HomeIcon from '@mui/icons-material/Home';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import AddIcon from '@mui/icons-material/AddTask';
import axios  from 'axios';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;

const SideDrawer = ({ mobileOpen, handleDrawerToggle }) => {

  const [userType,setUserType] = useState('')
  const [name,setName] = useState('')
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDashboard = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/dashboard`, {
          headers: {
            Authorization: token,
          },
        });

        setName(response.data.names);
        setUserType(response.data.message);

      } catch (err) {
        console.error(err);
        // setIsLogged(false)
      }
    };

    fetchDashboard();
  }, [navigate]);

  const drawer = (
    <div style={{background:'#1C6BD3',height:'100vh'}}>
      <Toolbar style={{ marginTop: '1rem' }} />
      <List>
        <ListItem button component={Link} to="/">
          <ListItemIcon style={{color:'white'}}><HomeIcon/></ListItemIcon>
          <ListItemText primary="Home" style={{color:'white'}}/>
        </ListItem>
        <ListItem button component={Link} to="/dashboard">
          <ListItemIcon><DashboardIcon style={{color:'white'}} /></ListItemIcon>
          <ListItemText primary="Dashboard" style={{color:'white'}}/>
        </ListItem>
        
        {userType =='SuperAdmin'?
        <>
        {/* <ListItem button component={Link} to="/students">
          <ListItemIcon><MenuBookIcon style={{color:'white'}}/></ListItemIcon>
          <ListItemText primary="Students" style={{color:'white'}}/>
        </ListItem> */}
        <ListItem button component={Link} to="/application">
          <ListItemIcon><SubscriptionsIcon style={{color:'white'}}/></ListItemIcon>
          <ListItemText primary="Applications" style={{color:'white'}} />
        </ListItem>
        <ListItem button component={Link} to="/schools">
          <ListItemIcon><SectionIcon style={{color:'white'}}/></ListItemIcon>
          <ListItemText primary="Schools" style={{color:'white'}}/>
        </ListItem>
        <ListItem button component={Link} to="/admin/blog">
          <ListItemIcon><PeopleIcon style={{color:'white'}}/></ListItemIcon>
          <ListItemText primary="Blog" style={{color:'white'}}/>
        </ListItem>
        <ListItem button component={Link} to="/users">
          <ListItemIcon><PeopleIcon style={{color:'white'}}/></ListItemIcon>
          <ListItemText primary="Users" style={{color:'white'}}/>
        </ListItem>
        <ListItem button component={Link} to="/team">
          <ListItemIcon><GroupIcon style={{color:'white'}}/></ListItemIcon>
          <ListItemText primary="Team" style={{color:'white'}}/>
        </ListItem>
        <ListItem button component={Link} to="/location">
          <ListItemIcon><InfoIcon style={{color:'white'}}/></ListItemIcon>
          <ListItemText primary="Destinations" style={{color:'white'}}/>
        </ListItem>
        <ListItem button component={Link} to="/suggestions">
          <ListItemIcon><InfoIcon style={{color:'white'}}/></ListItemIcon>
          <ListItemText primary="Suggestions" style={{color:'white'}}/>
        </ListItem>
        <ListItem button component={Link} to="/webcontents" >
          <ListItemIcon><WebIcon style={{color:'white'}}/></ListItemIcon>
          <ListItemText primary="Web Settings"  style={{color:'white'}}/>
        </ListItem>
        <ListItem button component={Link} to="/manage-details">
          <ListItemIcon>
            <AddIcon style={{color:'white'}}/>
          </ListItemIcon>
          <ListItemText primary="Manage Details" style={{color:'white'}}/>
        </ListItem>
        </>:
        userType =='Admin'?
        <>
        <ListItem button component={Link} to="/application">
          <ListItemIcon><SubscriptionsIcon style={{color:'white'}}/></ListItemIcon>
          <ListItemText primary="Applications" style={{color:'white'}} />
        </ListItem>
        <ListItem button component={Link} to="/schools">
          <ListItemIcon><SectionIcon style={{color:'white'}}/></ListItemIcon>
          <ListItemText primary="Schools" style={{color:'white'}}/>
        </ListItem>
        <ListItem button component={Link} to="/admin/blog">
          <ListItemIcon><PeopleIcon style={{color:'white'}}/></ListItemIcon>
          <ListItemText primary="Blog" style={{color:'white'}}/>
        </ListItem>
        <ListItem button component={Link} to="/team">
          <ListItemIcon><GroupIcon style={{color:'white'}}/></ListItemIcon>
          <ListItemText primary="Team" style={{color:'white'}}/>
        </ListItem>
        <ListItem button component={Link} to="/location">
          <ListItemIcon><InfoIcon style={{color:'white'}}/></ListItemIcon>
          <ListItemText primary="Destinations" style={{color:'white'}}/>
        </ListItem>
        <ListItem button component={Link} to="/suggestions">
          <ListItemIcon><InfoIcon style={{color:'white'}}/></ListItemIcon>
          <ListItemText primary="Suggestions" style={{color:'white'}}/>
        </ListItem>
        <ListItem button component={Link} to="/manage-details">
          <ListItemIcon>
            <AddIcon style={{color:'white'}}/>
          </ListItemIcon>
          <ListItemText primary="Manage Details" style={{color:'white'}}/>
        </ListItem>
        </>
        :userType =='Student'?

        <>
        <ListItem button component={Link} to="/students-applications" >
          <ListItemIcon><WebIcon style={{color:'white'}}/></ListItemIcon>
          <ListItemText primary="My Applications" style={{color:'white'}}/>
        </ListItem>
        <ListItem button component={Link} to="/schools">
          <ListItemIcon><SectionIcon style={{color:'white'}}/></ListItemIcon>
          <ListItemText primary="Schools" style={{color:'white'}}/>
        </ListItem>
        <ListItem button component={Link} to="/message" >
          <ListItemIcon><WebIcon style={{color:'white'}}/></ListItemIcon>
          <ListItemText primary="Message" style={{color:'white'}}/>
        </ListItem>
        <ListItem button component={Link} to="/profile" >
          <ListItemIcon><WebIcon style={{color:'white'}}/></ListItemIcon>
          <ListItemText primary="Profile" style={{color:'white'}}/>
        </ListItem>
        <ListItem button component={Link} to="/dashboard" >
          <ListItemIcon><WebIcon style={{color:'white'}}/></ListItemIcon>
          <ListItemText primary="Logout"  style={{color:'white'}}/>
        </ListItem>
        </>:null}
      </List>
    </div>
  );

  return (
    <>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }} // Better open performance on mobile.
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default SideDrawer;
