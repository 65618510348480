import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import store from './store';
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import Loading from './components/Loading';
import AdminBlogPage from './pages/AdminBlogPage';
import CountryDetails from './pages/CountryDetails';
import AddCountryDetails from './pages/AddCountryDetails';
import ManageDetails from './pages/ManageDetails';
import StudyInCanadaOverview from './components/StudyInCanadaOverview';

const Home = lazy(() => import('./pages/LandingPage'));
const Contact = lazy(() => import('./pages/Contact'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const TravelTours = lazy(() => import('./pages/TravelToursPage'));
const RwandaEducation = lazy(() => import('./pages/RwandaEducarionPage'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const BrandsHome = lazy(() => import('./brands/BrandsHome'));
const LandingBrand = lazy(() => import('./brands/LandingBrand'));
const StudentsForm = lazy(() => import('./brands/StudentsForm'));
const Schools = lazy(() => import('./pages/SchoolsController'));
const FlightPage = lazy(() => import('./brands/FlightPage'));
const LandingVisa = lazy(() => import('./visas/LandingVisa'));
const CampsHome = lazy(() => import('./brands/CompsHome'));
const Locations = lazy(() => import('./pages/Locations'));
const Users = lazy(() => import('./pages/Users'));
const Students = lazy(() => import('./pages/Students'));
const Applications = lazy(() => import('./pages/Applications'));
const TeamController = lazy(() => import('./pages/TeamController'));
const Suggestions = lazy(() => import('./pages/Suggestion'));
const StudentApplications = lazy(() => import('./pages/StudentApplications'));
const Notifications = lazy(() => import('./pages/Notifications'));
const Profile = lazy(() => import('./pages/Profile'));
const Message = lazy(() => import('./pages/Message'));
const AuthPage = lazy(() => import('./pages/AuthPage'));
const InternshipPage = lazy(() => import('./pages/InternshipPage'));
const Internship = lazy(() => import('./internship/Internship'));
const BlogPage = lazy(() => import('./pages/BlogPage'));


function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <div className="App">
            <Suspense fallback={<Loading />}>
              <AnimatePresence mode='wait'>
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Home />
                      </motion.div>
                    }
                  />
                  <Route
                    exact
                    path="/home"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Home />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/about"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <AboutUs />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/contact"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Contact />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/login"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <LoginPage />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/dashboard"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Dashboard />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/webcontents"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Dashboard />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/travel-tours"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <TravelTours />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/rwanda-education"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <RwandaEducation />
                      </motion.div>
                    }
                  />
                  <Route path="/study-in-canada" element={<StudyInCanadaOverview />} />
                  <Route
                    path="/business-abroad"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <BrandsHome />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/study-abroad"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <LandingBrand />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/schools"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Schools />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/services/service-details/:id/:brandid"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <LandingBrand />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/services/application-form"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <StudentsForm />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/air-ticket"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <FlightPage />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/summer-camp"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <CampsHome />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/visa"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <LandingVisa />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/international-camps"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <CampsHome />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/location"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Locations />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/students"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Students />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/application"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Applications />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/schools"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Schools />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/users"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Users />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/team"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <TeamController />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/suggestions"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Suggestions />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/students-applications"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <StudentApplications />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/notifications"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Notifications />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/profile"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Profile />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/message"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Message />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/authpage"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <AuthPage />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/internship"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Internship />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/blog"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <BlogPage />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/admin/blog"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <AdminBlogPage />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/admin/add-country"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <AddCountryDetails />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/country/:id"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <CountryDetails />
                      </motion.div>
                    }
                  />
                  <Route
                    path="/manage-details"
                    element={
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <ManageDetails />
                      </motion.div>
                    }
                  />

                  {/* Redirect to homepage if page not found */}
                  <Route path="*" element={<Navigate to="/home" replace />} />
                </Routes>
              </AnimatePresence>
            </Suspense>
          </div>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
