import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity, faBookOpen, faCertificate } from '@fortawesome/free-solid-svg-icons';
import { useCountry } from '../context/CountryContext';
import { useTranslation } from 'react-i18next';

const TopPrograms = () => {
  const { country } = useCountry();
  const { t } = useTranslation();

  return (
    <div style={{
      padding: '40px 20px',
      maxWidth: '1200px',
      margin: '50px auto',
      backgroundColor: '#f8f9fa'
    }}>
      <h2 style={{
        fontSize: '2.5em',
        textAlign: 'center',
        marginBottom: '40px',
        color: '#1C6BD3',
        position: 'relative'
      }}>
        <FontAwesomeIcon icon={faUniversity} style={{ marginRight: '15px' }} />
        {t('topPrograms')} {country.name}
      </h2>

      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        gap: '30px',
        padding: '20px'
      }}>
        {country.programs?.top?.map((program, index) => (
          <div key={index} style={{
            backgroundColor: '#fff',
            padding: '25px',
            borderRadius: '15px',
            boxShadow: '0 8px 30px rgba(0,0,0,0.12)',
            transition: 'transform 0.3s ease',
            ':hover': {
              transform: 'translateY(-5px)'
            }
          }}>
            <FontAwesomeIcon 
              icon={faBookOpen} 
              style={{ 
                fontSize: '2em', 
                color: '#1C6BD3',
                marginBottom: '15px'
              }} 
            />
            <h3 style={{
              fontSize: '1.4em',
              marginBottom: '10px',
              color: '#2c3e50'
            }}>
              {program}
            </h3>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '15px'
            }}>
              <span style={{
                backgroundColor: '#1C6BD3',
                color: 'white',
                padding: '5px 10px',
                borderRadius: '15px',
                fontSize: '0.9em'
              }}>
                #{index + 1} in {country.name}
              </span>
            </div>
            <p style={{
              color: '#7f8c8d',
              lineHeight: '1.6',
              marginBottom: '20px'
            }}>
              {t('comprehensiveProgram')}
            </p>
            <button style={{
              width: '100%',
              padding: '12px',
              backgroundColor: '#1C6BD3',
              color: 'white',
              border: 'none',
              borderRadius: '8px',
              cursor: 'pointer',
              transition: 'background-color 0.3s'
            }}>
              {t('exploreProgram')}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopPrograms;
