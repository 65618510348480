import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Container, Grid } from '@mui/material';
import axios from 'axios';
import EducationSystem from '../components/EducationSystem';
import PopularPrograms from '../components/PopularPrograms';
import AdmissionRequirements from '../components/AdmissionRequirements';
import CostOfLiving from '../components/CostOfLiving';
import VisaInformation from '../components/VisaInformation';

function CountryDetails() {
  const { id } = useParams();
  const [country, setCountry] = useState(null);
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/country/${id}`);
        setCountry(response.data.country);
        setSchools(response.data.schools);
      } catch (error) {
        console.error('Error fetching country details:', error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      {country && (
        <Box mb={4}>
          <Typography variant="h2" component="h1" gutterBottom>
            {country.name}
          </Typography>
          <Typography variant="body1" component="p">
            {country.description}
          </Typography>
          <EducationSystem data={country.educationSystem} />
          <PopularPrograms data={country.popularPrograms} />
          <AdmissionRequirements data={country.admissionRequirements} />
          <CostOfLiving data={country.costOfLiving} />
          <VisaInformation data={country.visaInformation} />
        </Box>
      )}
      <Grid container spacing={4}>
        {schools.map((school) => (
          <Grid item xs={12} md={6} key={school.id}>
            <Box sx={{ p: 2, border: '1px solid #ccc', borderRadius: 2 }}>
              <Typography variant="h5" component="h2">
                {school.name}
              </Typography>
              <Typography variant="body2" component="p">
                {school.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default CountryDetails;
