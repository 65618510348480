import React from 'react';
import { useCountry } from '../context/CountryContext';
import { LinearProgress, Box, Typography } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import WorkIcon from '@mui/icons-material/Work';
import TranslateIcon from '@mui/icons-material/Translate';

const TuitionAndWorkOverview = () => {
  const { country } = useCountry();

  const calculateProgress = (value, max) => (value / max) * 100;

  return (
    <div style={{
      padding: '40px 20px',
      maxWidth: '1200px',
      margin: '50px auto',
      backgroundColor: '#f8f9fa',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
    }}>
      <h2 style={{
        fontSize: '2.5em',
        textAlign: 'center',
        marginBottom: '40px',
        color: '#1C6BD3'
      }}>
        Tuition & Work Overview
      </h2>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
        <Box>
          <Typography variant="h6" sx={{ marginBottom: '20px', color: '#1C6BD3' }}>
            Overview
          </Typography>
          <Typography variant="body1" sx={{ lineHeight: '1.6' }}>
            {country.tuitionWorkSummary}
          </Typography>
        </Box>

        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
            <AttachMoneyIcon sx={{ color: '#1C6BD3' }} />
            <Typography variant="h6">Tuition Costs</Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={calculateProgress(country.tuitionCostLow, country.tuitionCostHigh)}
            sx={{ height: '10px', borderRadius: '5px', backgroundColor: '#e0e0e0' }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
            <Typography variant="body2">Low: ${country.tuitionCostLow}</Typography>
            <Typography variant="body2">High: ${country.tuitionCostHigh}</Typography>
          </Box>
        </Box>

        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
            <WorkIcon sx={{ color: '#1C6BD3' }} />
            <Typography variant="h6">Work While Studying</Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={calculateProgress(country.workWhileStudyingHours, 40)}
            sx={{ height: '10px', borderRadius: '5px', backgroundColor: '#e0e0e0' }}
          />
          <Typography variant="body2" sx={{ marginTop: '10px' }}>
            {country.workWhileStudyingHours} hours/week
          </Typography>
        </Box>

        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
            <TranslateIcon sx={{ color: '#1C6BD3' }} />
            <Typography variant="h6">Language Proficiency</Typography>
          </Box>
          <Typography variant="body2">
            {country.languageProficiencyRequirements}
          </Typography>
        </Box>

        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
            <WorkIcon sx={{ color: '#1C6BD3' }} />
            <Typography variant="h6">Work Permit After Study</Typography>
          </Box>
          <Typography variant="body2">
            Duration: {country.workPermitAfterStudyDuration}
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default TuitionAndWorkOverview;
