import React, { Component,useEffect } from 'react';
import StudyInCanada from './StudyInCanada';
import TopPrograms from './TopPrograms';
import PopularFields from './PopularFields';
import VisaProcess from './VisaProcess';
import StudyBenefits from './StudyBenefits';
import Header from './header';
import Footer from './Footer';
import TuitionAndWorkOverview from './TuitionAndWorkOverview';
import { useCountry } from '../context/CountryContext';
import { COUNTRIES } from '../config/countries';
import './StudyInCanadaOverview.css';

class ErrorBoundary extends React.Component {
  state = { hasError: false };
  
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error('Error Boundary Caught:', error);
    console.log('Failing component stack:', info.componentStack);
    console.log('Error Name:', error.name);
    console.log('Error Message:', error.message);
    console.log('Error Stack:', error.stack);
  }

  render() {
    return this.state.hasError
      ? <div className="error-fallback">Component Failed to Load</div>
      : this.props.children;
  }
}

const StudyInCanadaOverview = () => {
  const { country, setCountry } = useCountry();
  useEffect(() => {
    if (!country || country.name !== 'Canada') {
      setCountry(COUNTRIES['CA']);
    }
  }, []);

  return (
    <div className="main-container">
      <h1 className="title">Welcome {country.name}</h1>
      <div className="button-container">
        {Object.entries(COUNTRIES).map(([code, country]) => (
          <button
            key={code}
            onClick={() => setCountry(COUNTRIES[code])}
            className="country-button"
          >
            {country.name}
          </button>
        ))}
      </div>
      <Header />
      <ErrorBoundary>
        <div className="overview-container">
          <TuitionAndWorkOverview />
          <StudyBenefits />
          <TopPrograms />
          <PopularFields />
          <VisaProcess />
          <StudyInCanada />
        </div>
        <div className="button-container">
        {Object.entries(COUNTRIES).map(([code, country]) => (
          <button
            key={code}
            onClick={() => setCountry(COUNTRIES[code])}
            className="country-button"
          >
            {country.name}
          </button>
        ))}
      </div>
      </ErrorBoundary>
      <Footer />
    </div>
  );
};

export default StudyInCanadaOverview;
