import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Divider,
  Button,
  Grid,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import logo from '../assets/images/logo.png';
import ApplyNowButton from '../brands/ApplyButton';
import axios  from 'axios';
import { useCountry } from '../context/CountryContext';
import { COUNTRIES } from '../config/countries';

const navItems = [
  {
    name: 'Other Services',
    link: '',
    subItems: [
      { name: 'International Camps', link: '/summer-camp' },
      { name: 'Air Ticket', link: '/air-ticket' },
      { name: 'Visa', link: '/visa' },
      { name: 'International Internships', link: '/internship' },
    ],
  },
  // { name: 'Blog', link: '/blog' },
  { name: 'About Us', link: '/about' },
  { name: 'Contact', link: '/contact' },
];
const authItems = [{ name: 'Sign In', link: '/authpage' }];
const account = [{ name: 'My Account', link: '/dashboard' }];

const useStyles = makeStyles({
  navBarScrolled: {
    backgroundColor: '#1976d2',
  },
  activeMenu: {
    backgroundColor: '#1976d2',
    color: '#fff',
  },
  menuButton: {
    textTransform: 'capitalize',
    borderWidth:2,
    borderColor:'#1976d2',
    fontSize: 20,
    px: 2,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#03adfc',
      color:'#1976d2',
      borderWidth:2,
      borderColor:'#1976d2',
    },
  },
  dropdownMenu: {
    color: '#fff',
    Width: 450,
  },
});

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [countriesAnchorEl, setCountriesAnchorEl] = useState(null);
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [isLogged,setIsLogged] = useState(false);
  const navigate = useNavigate();

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const fetchDashboard = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/dashboard`, {
          headers: {
            Authorization: token,
          },
        });
        setIsLogged(true)

      } catch (err) {
        console.error(err);
        setIsLogged(false)
      }
    };

    fetchDashboard();
  }, [navigate]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCountriesMenuOpen = (event) => {
    setCountriesAnchorEl(event.currentTarget);
  };

  const handleCountriesMenuClose = () => {
    setCountriesAnchorEl(null);
  };

  const handleCountrySelect = (countryCode) => {
    const country = COUNTRIES[countryCode];
    if (country && country.route) {
      navigate(country.route);
    }
    handleCountriesMenuClose();
  };

  return (
    <>
      {/* Top Contact Header */}
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backgroundColor: '#1976d2',
          color: 'white',
          display: isScrolled ? 'none' : 'block',
          padding: '5px 20px',
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box display="flex" alignItems="center" gap={2}>
            <PhoneIcon />
            <Typography variant="body2">+250 788 447 599</Typography>
            <EmailIcon sx={{ ml: 2 }} />
            <Typography variant="body2">info@earthlinkgroup.com</Typography>
          </Box>
          <Box>
            {[FacebookIcon, TwitterIcon, LinkedInIcon, YouTubeIcon].map((Icon, index) => (
              <IconButton key={index} sx={{ color: 'white' }} size="small">
                <Icon />
              </IconButton>
            ))}
          </Box>
        </Toolbar>
      </AppBar>

      {/* Countries Dropdown Menu */}
      <Menu
        anchorEl={countriesAnchorEl}
        open={Boolean(countriesAnchorEl)}
        onClose={handleCountriesMenuClose}
        PaperProps={{
          sx: {
            maxWidth: 650,
            maxHeight: 450,
            overflow: 'auto',
          },
        }}
      >
        <Box p={2}>
          <Typography variant="h6" gutterBottom>
            Choose Your Study Destination
          </Typography>
          <Grid container spacing={2}>
            {Object.entries(COUNTRIES).map(([code, country]) => (
              <Grid item xs={4} key={code}>
                <Link to="/study-in-canada">
        
                <MenuItem 
                  onClick={() => handleCountrySelect(code)}
                  sx={{ 
                    flexDirection: 'column', 
                    alignItems: 'center',
                    textAlign: 'center',
                    gap: 1,
                    justifyContent: 'center',
                  }}
                >
                  <img 
                    src={country.flag} 
                    alt={`${country.name} flag`} 
                    style={{ 
                      width: 60, 
                      height: 40, 
                      objectFit: 'cover',
                      borderRadius: 4 
                    }} 
                  />
                  <Typography variant="caption">
                    {country.name}
                  </Typography>
                </MenuItem>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Menu>

      {/* Main Header */}
      <AppBar
        position="fixed"
        elevation={isScrolled ? 4 : 0}
        sx={{
          backgroundColor: isScrolled ? 'white' : 'transparent',
          color: isScrolled ? 'black' : 'white',
          transition: 'background-color 0.5s ease, color 0.5s ease',
          padding: isScrolled ? '10px 20px' : '20px 40px',
          top: isScrolled ? '0px' : '50px',
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          {/* Logo */}
          <Link to="/">
            <img
              src={logo}
              alt="Logo"
              style={{
                height: isMobile ? '40px' : '60px',
                cursor: 'pointer',
              }}
            />
          </Link>

          {/* Navigation Menu */}
          {isMobile ? (
            <IconButton onClick={toggleDrawer} sx={{ color: isScrolled ? 'black' : 'white' }}>
              <MenuIcon />
            </IconButton>
          ) : (
            <Box display="flex" alignItems="center" gap={2}>
              {/* Home Menu Item */}
              <Link
                to="/"
                style={{
                  textDecoration: 'none',
                  fontWeight: '600',
                  fontSize: '18px',
                  color: isScrolled ? '#1976d2' : 'blue',
                  transition: 'color 0.3s ease',
                }}
              >
                Home
              </Link>

              {/* Countries Dropdown Trigger */}
              <Typography
                aria-controls="countries-menu"
                aria-haspopup="true"
                onMouseEnter={handleCountriesMenuOpen}
                sx={{
                  cursor: 'pointer',
                  fontWeight: '600',
                  fontSize: '18px',
                  color: isScrolled ? '#1976d2' : 'blue',
                  transition: 'color 0.3s ease',
                }}
              >
                Study Abroad
              </Typography>
              {navItems.map((item, index) =>
                item.subItems ? (
                  <Box key={index}>
                    <Typography
                      aria-controls="menu"
                      aria-haspopup="true"
                      onMouseEnter={handleMenuOpen}
                      sx={{
                        cursor: 'pointer',
                        fontWeight: '600',
                        fontSize: '18px',
                        color: isScrolled ? '#1976d2' : 'blue',
                        transition: 'color 0.3s ease',
                      }}
                    >
                      {item.name}
                    </Typography>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      {item.subItems.map((subItem, subIndex) => (
                        <MenuItem
                          key={subIndex}
                          component={Link}
                          to={subItem.link}
                          onClick={handleMenuClose}
                        >
                          {subItem.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                ) : (
                  <Link
                    key={index}
                    to={item.link}
                    style={{
                      textDecoration: 'none',
                      fontWeight: '600',
                      color: isScrolled ? '#1976d2' : 'blue',
                    }}
                  >
                    {item.name}
                  </Link>
                )
              )}
              {isLogged?<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {account.map((item) => (
                  <Button
                    key={item.name}
                    component={Link}
                    to={item.link}
                    className={classes.menuButton}
                    sx={{
                      backgroundColor: location.pathname === item.link ? '#1976d2' : '#1976d2',
                      color: location.pathname === item.link ? '#000' : '#fff',
                      fontSize:18,
                    }}
                  >
                    {item.name.toLowerCase()}
                  </Button>
                ))}
                <ApplyNowButton />
              </Box>:
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {authItems.map((item) => (
                  <Button
                    key={item.name}
                    component={Link}
                    to={item.link}
                    className={classes.menuButton}
                    sx={{
                      backgroundColor: location.pathname === item.link ? '#1976d2' : '#1976d2',
                      color: location.pathname === item.link ? '#000' : '#fff',
                      fontSize:18,
                    }}
                  >
                    {item.name.toLowerCase()}
                  </Button>
                ))}
                <ApplyNowButton />
              </Box>
              }
            </Box>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer for Mobile */}
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
        <Box width={250}>
          <List>
            {navItems.map((item, index) =>
              item.subItems ? (
                <List key={index}>
                  <ListItem>
                    <Typography variant="h6">{item.name}</Typography>
                  </ListItem>
                  {item.subItems.map((subItem, subIndex) => (
                    <ListItem
                      key={subIndex}
                      button
                      component={Link}
                      to={subItem.link}
                      onClick={toggleDrawer}
                    >
                      <ListItemText primary={subItem.name} />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <ListItem
                  key={index}
                  button
                  component={Link}
                  to={item.link}
                  onClick={toggleDrawer}
                >
                  <ListItemText primary={item.name} />
                </ListItem>
              )
            )}
          </List>
          <Divider flexItem sx={{ mx: 1, display: { xs:'block',marginBottom:10 } }} />
          <Box sx={{ display: 'flex', gap: 2,flexDirection:'column',padding:5 }}>
            {authItems.map((item) => (
              <Button
                key={item.name}
                component={Link}
                to={item.link}
                className={classes.menuButton}
                sx={{
                  backgroundColor: location.pathname === item.link ? '#1976d2' : '#1976d2',
                  color: location.pathname === item.link ? '#000' : '#fff',
                  fontSize:18,
                }}
              >
                {item.name.toLowerCase()}
              </Button>
            ))}
            <ApplyNowButton />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default Header;
