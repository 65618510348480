// abroad/src/pages/AdminBlogPage.js
import React, { useState } from 'react';
import { Container, TextField, Button, Box, FormControlLabel, Checkbox } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AppBar from '../components/AppBar';
import Drawer from '../components/Drawer';
import { enqueueSnackbar } from '../components/Notifier';

export default function AdminBlogPage() {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const initialFormState = {
    title: '',
    content: '',
    featuredImage: '',
    isFeatured: false,
    category: 'latest',
    twitterUrl: '',
    slug: ''
  };

  const [formData, setFormData] = useState(initialFormState);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setPreviewUrl(URL.createObjectURL(selectedFile));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    const token = localStorage.getItem('token');
    const formDataToSend = new FormData();

    // Append all form fields
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend.append(key, value);
    });

    // Append file if exists
    if (file) {
      formDataToSend.append('featuredImage', file);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/blog`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formDataToSend
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Blog submission failed');
      }

      // Reset form on success
      setFormData(initialFormState);
      setFile(null);
      setSubmitting(false);

      enqueueSnackbar('Blog post created successfully!', {
        variant: 'success',
        autoHideDuration: 3000
      });

    } catch (err) {
      setSubmitting(false);
      enqueueSnackbar(err.message || 'Error submitting blog post', {
        variant: 'error',
        autoHideDuration: 5000
      });
      console.error('Submission error:', err);
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <AppBar onMenuClick={() => setDrawerOpen(!drawerOpen)} />
      <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />

      <Container maxWidth="md" sx={{ py: 15 }}>
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Title"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            sx={{ mb: 2 }}
          />

          <ReactQuill
            value={formData.content}
            onChange={(value) => setFormData({ ...formData, content: value })}
            style={{ height: '300px', marginBottom: '40px' }}
          />

          <TextField
            fullWidth
            label="Featured Image URL"
            value={formData.featuredImage}
            onChange={(e) => setFormData({ ...formData, featuredImage: e.target.value })}
            sx={{ mb: 2 }}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={formData.isFeatured}
                onChange={(e) => setFormData({ ...formData, isFeatured: e.target.checked })}
              />
            }
            label="Featured Post"
            sx={{ mb: 2 }}
          />

          <TextField
            fullWidth
            label="Twitter URL"
            value={formData.twitterUrl}
            onChange={(e) => setFormData({ ...formData, twitterUrl: e.target.value })}
            sx={{ mb: 2 }}
          />
          <Box sx={{ mb: 2 }}>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="featured-image-upload"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="featured-image-upload">
              <Button variant="contained" component="span">
                Upload Featured Image
              </Button>
            </label>
            {previewUrl && (
              <Box sx={{ mt: 2 }}>
                <img src={previewUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
              </Box>
            )}
          </Box>

          <Button type="submit" variant="contained" disabled={submitting}>
            {submitting ? 'Publishing...' : 'Publish Post'}
          </Button>
        </Box>
      </Container>
    </Box>
  );
}