import { faMicroscope, faFlask, faBriefcase, faBalanceScale, faLanguage, faFileAlt, faCheckCircle, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { useCountry } from '../context/CountryContext';

export const getFieldIcon = (field) => {
  const icons = {
    'Technology': faMicroscope,
    'Healthcare': faFlask,
    'Business': faBriefcase,
    'Law': faBalanceScale,
    'Languages': faLanguage
  };
  return icons[field.split(' ')[0]] || faFileAlt;
};

export const getStepIcon = (index) => {
  return [faFileAlt, faCheckCircle, faHourglassHalf, faCheckCircle][index % 4];
};

export const getStepDescription = (countryCode, stepIndex) => {
  const descriptions = {
    CA: [
      'Submit complete application package with all required documents',
      'Schedule and complete biometrics appointment',
      'Typical processing duration for Canadian study permits',
      'Receive final decision via email notification'
    ],
    US: [
      'Complete online nonimmigrant visa application',
      'Schedule visa interview at nearest consulate',
      'Attend in-person interview with consular officer',
      'Final administrative processing before visa issuance'
    ]
  };
  return descriptions[countryCode]?.[stepIndex] || 'Standard visa processing step';
};
