import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faUserGraduate, faGlobeAmericas, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { useCountry } from '../context/CountryContext';

const StudyBenefits = () => {
  const { country } = useCountry();

  return (
    <div style={{ 
      padding: '40px 20px',
      maxWidth: '1200px',
      margin: '0 auto',
      backgroundColor: '#f8f9fa'
    }}>
      <h2 style={{
        fontSize: '2.5em',
        textAlign: 'center',
        marginBottom: '40px',
        color: '#1C6BD3',
        position: 'relative'
      }}>
        <FontAwesomeIcon icon={faChartLine} style={{ marginRight: '15px' }} />
        Why Study in {country.name}?
      </h2>

      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
        gap: '30px',
        padding: '20px'
      }}>
        {country.benefits?.map((benefit, index) => (
          <div key={index} style={{
            backgroundColor: '#fff',
            padding: '30px',
            borderRadius: '15px',
            boxShadow: '0 8px 30px rgba(0,0,0,0.12)',
            transition: 'transform 0.3s ease',
            ':hover': {
              transform: 'translateY(-5px)'
            }
          }}>
            {index === 0 && (
              <FontAwesomeIcon 
                icon={faGraduationCap} 
                style={{ 
                  fontSize: '2.5em', 
                  color: '#1C6BD3',
                  marginBottom: '20px'
                }} 
              />
            )}
            {index === 1 && (
              <FontAwesomeIcon 
                icon={faGlobeAmericas} 
                style={{ 
                  fontSize: '2.5em', 
                  color: '#1C6BD3',
                  marginBottom: '20px'
                }} 
              />
            )}
            {index === 2 && (
              <FontAwesomeIcon 
                icon={faUserGraduate} 
                style={{ 
                  fontSize: '2.5em', 
                  color: '#1C6BD3',
                  marginBottom: '20px'
                }} 
              />)}
            <h3 style={{
              fontSize: '1.5em',
              marginBottom: '15px',
              color: '#2c3e50'
            }}>
              {index === 0 && 'Career Advancement'}
              {index === 1 && 'Global Network'}
              {index === 2 && 'Personal Development'}
            </h3>
            <div style={{
              height: '8px',
              backgroundColor: '#ecf0f1',
              borderRadius: '4px',
              marginBottom: '20px'
            }}>
              <div style={{
                width: index === 0 ? '85%' : index === 1 ? '78%' : '92%',
                height: '100%',
                backgroundColor: '#1C6BD3',
                borderRadius: '4px',
                transition: 'width 0.5s ease'
              }} />
            </div>
            <p style={{
              color: '#7f8c8d',
              lineHeight: '1.6',
              fontSize: '1.1em'
            }}>
              {benefit}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StudyBenefits;
