// c:\Projects\earthlink-web\abroad\src\components\Notifier.js
import { useSnackbar } from 'notistack';

let useSnackbarRef;
export const SnackbarUtilsConfigurator = () => {
    useSnackbarRef = useSnackbar();
    return null;
};

export const enqueueSnackbar = (message, options) => {
    useSnackbarRef.enqueueSnackbar(message, options);
};