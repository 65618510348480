import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Container, Input } from '@mui/material';
import axios from 'axios';

function AddCountryDetails() {
  const [countryName, setCountryName] = useState('');
  const [countryDescription, setCountryDescription] = useState('');
  const [educationSystem, setEducationSystem] = useState('');
  const [popularPrograms, setPopularPrograms] = useState('');
  const [admissionRequirements, setAdmissionRequirements] = useState('');
  const [costOfLiving, setCostOfLiving] = useState('');
  const [visaInformation, setVisaInformation] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [schoolDescription, setSchoolDescription] = useState('');
  const [countryImage, setCountryImage] = useState(null);
  const [countrySVG, setCountrySVG] = useState(null);

  const handleCountrySubmit = async () => {
    const formData = new FormData();
    formData.append('name', countryName);
    formData.append('description', countryDescription);
    formData.append('educationSystem', educationSystem);
    formData.append('popularPrograms', popularPrograms);
    formData.append('admissionRequirements', admissionRequirements);
    formData.append('costOfLiving', costOfLiving);
    formData.append('visaInformation', visaInformation);
    if (countryImage) formData.append('countryImage', countryImage);
    if (countrySVG) formData.append('countrySVG', countrySVG);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/country`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Country details added successfully!');
    } catch (error) {
      console.error('Error adding country details:', error);
    }
  };

  const handleSchoolSubmit = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/school`, {
        name: schoolName,
        description: schoolDescription,
      });
      alert('School details added successfully!');
    } catch (error) {
      console.error('Error adding school details:', error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 8 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Add Country Details
      </Typography>
      <Box component="form" noValidate autoComplete="off">
        <TextField
          fullWidth
          label="Country Name"
          value={countryName}
          onChange={(e) => setCountryName(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Country Description"
          value={countryDescription}
          onChange={(e) => setCountryDescription(e.target.value)}
          margin="normal"
          multiline
          rows={4}
        />
        <TextField
          fullWidth
          label="Education System"
          value={educationSystem}
          onChange={(e) => setEducationSystem(e.target.value)}
          margin="normal"
          multiline
          rows={4}
        />
        <TextField
          fullWidth
          label="Popular Programs"
          value={popularPrograms}
          onChange={(e) => setPopularPrograms(e.target.value)}
          margin="normal"
          multiline
          rows={4}
        />
        <TextField
          fullWidth
          label="Admission Requirements"
          value={admissionRequirements}
          onChange={(e) => setAdmissionRequirements(e.target.value)}
          margin="normal"
          multiline
          rows={4}
        />
        <TextField
          fullWidth
          label="Cost of Living"
          value={costOfLiving}
          onChange={(e) => setCostOfLiving(e.target.value)}
          margin="normal"
          multiline
          rows={4}
        />
        <TextField
          fullWidth
          label="Visa Information"
          value={visaInformation}
          onChange={(e) => setVisaInformation(e.target.value)}
          margin="normal"
          multiline
          rows={4}
        />
        <Input
          type="file"
          accept="image/*"
          onChange={(e) => setCountryImage(e.target.files[0])}
          style={{ margin: '16px 0' }}
        />
        <Input
          type="file"
          accept="image/svg+xml"
          onChange={(e) => setCountrySVG(e.target.files[0])}
          style={{ margin: '16px 0' }}
        />
        <Button variant="contained" color="primary" onClick={handleCountrySubmit} sx={{ mt: 2 }}>
          Add Country
        </Button>
      </Box>

      <Typography variant="h4" component="h1" gutterBottom sx={{ mt: 4 }}>
        Add School Details
      </Typography>
      <Box component="form" noValidate autoComplete="off">
        <TextField
          fullWidth
          label="School Name"
          value={schoolName}
          onChange={(e) => setSchoolName(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="School Description"
          value={schoolDescription}
          onChange={(e) => setSchoolDescription(e.target.value)}
          margin="normal"
          multiline
          rows={4}
        />
        <Button variant="contained" color="primary" onClick={handleSchoolSubmit} sx={{ mt: 2 }}>
          Add School
        </Button>
      </Box>
    </Container>
  );
}

export default AddCountryDetails;
