import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faFileAlt,
  faCheckCircle,
  faHourglassHalf,
  faPassport
} from '@fortawesome/free-solid-svg-icons';
import { useCountry } from '../context/CountryContext';
import { getStepIcon, getStepDescription } from '../utils/helpers';

const VisaProcess = () => {
  const { country } = useCountry();

  return (
    <div style={{
      padding: '40px 20px',
      maxWidth: '1200px',
      margin: '50px auto',
      backgroundColor: '#f8f9fa'
    }}>
      <h2 style={{
        fontSize: '2.5em',
        textAlign: 'center',
        marginBottom: '40px',
        color: '#1C6BD3'
      }}>
        <FontAwesomeIcon icon={faPassport} style={{ marginRight: '15px' }} />
        {country.name} Student Visa Process
      </h2>

      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
        gap: '30px',
        padding: '20px'
      }}>
        {country.visaSteps?.map((step, index) => (
          <div key={index} style={{
            backgroundColor: '#fff',
            padding: '25px',
            borderRadius: '15px',
            boxShadow: '0 8px 30px rgba(0,0,0,0.12)',
            textAlign: 'center'
          }}>
            <div style={{
              width: '60px',
              height: '60px',
              backgroundColor: '#1C6BD3',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 auto 20px'
            }}>
              <FontAwesomeIcon icon={getStepIcon(index)} style={{ color: 'white', fontSize: '1.5em' }} />
            </div>
            <h3 style={{
              fontSize: '1.3em',
              marginBottom: '10px',
              color: '#2c3e50'
            }}>{step}</h3>
            <p style={{ color: '#7f8c8d' }}>{getStepDescription(country.code, index)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VisaProcess;
