import canadaFlag from '../assets/flags/canada.png';
import usaFlag from '../assets/flags/usa.png';
import ukFlag from '../assets/flags/uk.png';
import australiaFlag from '../assets/flags/australia.png';
import germanyFlag from '../assets/flags/germany.png';
import franceFlag from '../assets/flags/france.png';
import polandFlag from '../assets/flags/poland.png';
import cyprusFlag from '../assets/flags/cyprus.png';
import malaysiaFlag from '../assets/flags/malaysia.png';
import irelandFlag from '../assets/flags/ireland.png';
import rwandaFlag from '../assets/flags/rwanda.png';
import chinaFlag from '../assets/flags/china.png';
import hungaryFlag from '../assets/flags/hungary.png';

export const COUNTRIES = {
  CA: {
    name: 'Canada',
    flag: canadaFlag,
    currency: 'CAD',
    language: 'English/French',
    benefits: [
      'Post-Graduation Work Permit',
      'Affordable Education',
      'Multicultural Society'
    ],
    programs: {
      top: ['Business Administration', 'Computer Science', 'Engineering'],
      fields: ['Technology', 'Healthcare', 'Sustainable Energy']
    },
    visaSteps: [
      'Application Submission',
      'Biometrics Collection',
      'Processing Period',
      'Final Decision'
    ],
    processingTime: '12-16 weeks',
    tuitionWorkSummary: 'Canada offers affordable tuition fees compared to other study destinations, with opportunities to work up to 20 hours per week during studies and a post-graduation work permit of up to 3 years.',
    languageProficiencyRequirements: 'IELTS 6.0 or equivalent',
    tuitionCostLow: 12000,
    tuitionCostHigh: 35000,
    workWhileStudyingHours: 20,
    workPermitAfterStudyDuration: '3 years'
  },
  US: {
    name: 'USA',
    flag: usaFlag,
    currency: 'USD',
    language: 'English',
    benefits: [
      'OPT Work Authorization',
      'Research Opportunities',
      'Diverse University Options'
    ],
    programs: {
      top: ['MBA', 'Computer Engineering', 'Liberal Arts'],
      fields: ['AI Research', 'Biotechnology', 'Financial Services']
    },
    visaSteps: [
      'Online Application',
      'Interview Scheduling',
      'Consular Interview',
      'Administrative Processing'
    ],
    processingTime: '8-10 weeks',
    tuitionWorkSummary: 'The US offers world-class education with higher tuition costs, allowing students to work up to 20 hours per week on-campus and Optional Practical Training (OPT) after graduation.',
    languageProficiencyRequirements: 'TOEFL 80 or IELTS 6.5',
    tuitionCostLow: 20000,
    tuitionCostHigh: 50000,
    workWhileStudyingHours: 20,
    workPermitAfterStudyDuration: '1-3 years'
  },
  UK: {
    name: 'United Kingdom',
    flag: ukFlag,
    currency: 'GBP',
    language: 'English',
    benefits: [
      'High Quality Education',
      'Global Recognition',
      'Cultural Diversity'
    ],
    programs: {
      top: ['Law', 'Medicine', 'Economics'],
      fields: ['Humanities', 'Sciences', 'Arts']
    },
    visaSteps: [
      'Tier 4 Visa Application',
      'Biometric Appointment',
      'Visa Decision'
    ],
    processingTime: '3-4 weeks',
    tuitionWorkSummary: 'The UK offers prestigious universities with moderate tuition fees, allowing students to work up to 20 hours per week during term time and a 2-year post-study work visa.',
    languageProficiencyRequirements: 'IELTS 6.0 or equivalent',
    tuitionCostLow: 15000,
    tuitionCostHigh: 40000,
    workWhileStudyingHours: 20,
    workPermitAfterStudyDuration: '2 years'
  },
  AU: {
    name: 'Australia',
    flag: australiaFlag,
    currency: 'AUD',
    language: 'English',
    benefits: [
      'Post-Study Work Opportunities',
      'High Living Standards',
      'Research Excellence'
    ],
    programs: {
      top: ['Environmental Science', 'Marine Biology', 'Business'],
      fields: ['Technology', 'Healthcare', 'Sustainable Energy']
    },
    visaSteps: [
      'Online Application',
      'Health Examination',
      'Visa Grant'
    ],
    processingTime: '4-6 weeks',
    tuitionWorkSummary: 'Australia offers high-quality education with moderate tuition fees, allowing students to work up to 40 hours per fortnight during studies and a 2-4 year post-study work visa.',
    languageProficiencyRequirements: 'IELTS 6.0 or equivalent',
    tuitionCostLow: 20000,
    tuitionCostHigh: 45000,
    workWhileStudyingHours: 40,
    workPermitAfterStudyDuration: '2-4 years'
  },
  DE: {
    name: 'Germany',
    flag: germanyFlag,
    currency: 'EUR',
    language: 'German',
    benefits: [
      'Tuition-Free Education',
      'Strong Economy',
      'Innovative Research'
    ],
    programs: {
      top: ['Mechanical Engineering', 'Automotive Engineering', 'Physics'],
      fields: ['Engineering', 'Sciences', 'Arts']
    },
    visaSteps: [
      'Visa Application',
      'Interview',
      'Visa Approval'
    ],
    processingTime: '3-5 weeks',
    tuitionWorkSummary: 'Germany offers low or no tuition fees at public universities, allowing students to work up to 20 hours per week during studies and an 18-month post-study work visa.',
    languageProficiencyRequirements: 'TestDaF or DSH for German programs, IELTS for English programs',
    tuitionCostLow: 0,
    tuitionCostHigh: 15000,
    workWhileStudyingHours: 20,
    workPermitAfterStudyDuration: '18 months'
  },
  FR: {
    name: 'France',
    flag: franceFlag,
    currency: 'EUR',
    language: 'French',
    benefits: [
      'World-Class Education',
      'Rich Cultural Experience',
      'Career Opportunities'
    ],
    programs: {
      top: ['Fashion Design', 'Culinary Arts', 'Business Management'],
      fields: ['Arts', 'Humanities', 'Business']
    },
    visaSteps: [
      'Campus France Registration',
      'Visa Application',
      'Interview'
    ],
    processingTime: '4-6 weeks',
    tuitionWorkSummary: 'France offers world-class education with moderate tuition fees, allowing students to work up to 20 hours per week during studies and a 2-year post-study work visa.',
    languageProficiencyRequirements: 'DELF/DALF',
    tuitionCostLow: 170,
    tuitionCostHigh: 10000,
    workWhileStudyingHours: 20,
    workPermitAfterStudyDuration: '2 years'
  },
  PL: {
    name: 'Poland',
    flag: polandFlag,
    currency: 'PLN',
    language: 'Polish/English',
    benefits: [
      'Low Cost of Living',
      'High-Quality Education',
      'EU Membership Advantages'
    ],
    programs: {
      top: ['Computer Science', 'Engineering', 'Business Management'],
      fields: ['IT', 'Renewable Energy', 'Medical Sciences']
    },
    visaSteps: [
      'University Acceptance',
      'Visa Application',
      'Health Insurance Proof',
      'Visa Interview'
    ],
    processingTime: '4-6 weeks',
    tuitionWorkSummary: 'Poland offers affordable education with opportunities to work up to 20 hours per week, and a post-study work permit for graduates.',
    languageProficiencyRequirements: 'IELTS 5.5 or equivalent',
    tuitionCostLow: 2000,
    tuitionCostHigh: 15000,
    workWhileStudyingHours: 20,
    workPermitAfterStudyDuration: '1-2 years'
  },
  CY: {
    name: 'Cyprus',
    flag: cyprusFlag,
    currency: 'EUR',
    language: 'Greek/English',
    benefits: [
      'Mediterranean Lifestyle',
      'English-Taught Programs',
      'Strategic Location'
    ],
    programs: {
      top: ['Business', 'Tourism Management', 'Maritime Studies'],
      fields: ['Hospitality', 'International Relations', 'Finance']
    },
    visaSteps: [
      'University Application',
      'Visa Documentation',
      'Financial Proof',
      'Visa Approval'
    ],
    processingTime: '3-5 weeks',
    tuitionWorkSummary: 'Cyprus provides a unique Mediterranean study experience with opportunities to work part-time and gain international exposure.',
    languageProficiencyRequirements: 'IELTS 5.5 or equivalent',
    tuitionCostLow: 5000,
    tuitionCostHigh: 20000,
    workWhileStudyingHours: 20,
    workPermitAfterStudyDuration: '1 year'
  },
  MY: {
    name: 'Malaysia',
    flag: malaysiaFlag,
    currency: 'MYR',
    language: 'Malay/English',
    benefits: [
      'Multicultural Environment',
      'Affordable Living Costs',
      'Modern Infrastructure'
    ],
    programs: {
      top: ['Engineering', 'Business', 'Information Technology'],
      fields: ['Biotechnology', 'Digital Media', 'Sustainable Development']
    },
    visaSteps: [
      'University Acceptance',
      'Student Pass Application',
      'Medical Examination',
      'Visa Issuance'
    ],
    processingTime: '3-4 weeks',
    tuitionWorkSummary: 'Malaysia offers an affordable and diverse study environment with opportunities to work part-time and gain international experience.',
    languageProficiencyRequirements: 'IELTS 5.5 or equivalent',
    tuitionCostLow: 4000,
    tuitionCostHigh: 15000,
    workWhileStudyingHours: 20,
    workPermitAfterStudyDuration: '1 year'
  },
  IE: {
    name: 'Ireland',
    flag: irelandFlag,
    currency: 'EUR',
    language: 'English/Irish',
    benefits: [
      'Tech Hub of Europe',
      'English-Speaking Country',
      'EU Membership'
    ],
    programs: {
      top: ['Software Engineering', 'Pharmaceutical Sciences', 'Business Analytics'],
      fields: ['Technology', 'Pharmaceuticals', 'Finance']
    },
    visaSteps: [
      'University Offer Letter',
      'Visa Application',
      'Proof of Funds',
      'Visa Interview'
    ],
    processingTime: '4-6 weeks',
    tuitionWorkSummary: 'Ireland offers world-class education with opportunities to work during studies and a post-study work visa.',
    languageProficiencyRequirements: 'IELTS 6.0 or equivalent',
    tuitionCostLow: 10000,
    tuitionCostHigh: 35000,
    workWhileStudyingHours: 20,
    workPermitAfterStudyDuration: '2 years'
  },
  RW: {
    name: 'Rwanda',
    flag: rwandaFlag,
    currency: 'RWF',
    language: 'Kinyarwanda/English/French',
    benefits: [
      'Emerging Education Hub',
      'Economic Growth',
      'Safe Environment'
    ],
    programs: {
      top: ['Development Studies', 'Business Administration', 'Technology'],
      fields: ['Social Innovation', 'Entrepreneurship', 'Digital Transformation']
    },
    visaSteps: [
      'University Acceptance',
      'Visa Application',
      'Health Insurance',
      'Background Check'
    ],
    processingTime: '3-5 weeks',
    tuitionWorkSummary: 'Rwanda offers unique opportunities for students interested in development and emerging markets.',
    languageProficiencyRequirements: 'IELTS 5.5 or equivalent',
    tuitionCostLow: 3000,
    tuitionCostHigh: 12000,
    workWhileStudyingHours: 15,
    workPermitAfterStudyDuration: '6-12 months'
  },
  CN: {
    name: 'China',
    flag: chinaFlag,
    currency: 'CNY',
    language: 'Mandarin/English',
    benefits: [
      "World's Second Largest Economy",
      'Rich Cultural Heritage',
      'Advanced Technology'
    ],
    programs: {
      top: ['Engineering', 'Chinese Language', 'International Business'],
      fields: ['Artificial Intelligence', 'Robotics', 'Traditional Medicine']
    },
    visaSteps: [
      'University Admission',
      'JW202 Form',
      'Visa Application',
      'Health Check'
    ],
    processingTime: '4-6 weeks',
    tuitionWorkSummary: 'China offers unique educational experiences with opportunities to learn Mandarin and gain insights into a rapidly developing economy.',
    languageProficiencyRequirements: 'HSK 4 or IELTS 5.5',
    tuitionCostLow: 3000,
    tuitionCostHigh: 20000,
    workWhileStudyingHours: 10,
    workPermitAfterStudyDuration: '1 year'
  },
  HU: {
    name: 'Hungary',
    flag: hungaryFlag,
    currency: 'HUF',
    language: 'Hungarian/English',
    benefits: [
      'Affordable Education',
      'Central European Location',
      'High-Quality Universities'
    ],
    programs: {
      top: ['Medicine', 'Engineering', 'Business'],
      fields: ['Medical Sciences', 'Information Technology', 'Economics']
    },
    visaSteps: [
      'University Acceptance',
      'Visa Application',
      'Proof of Finances',
      'Health Insurance'
    ],
    processingTime: '4-5 weeks',
    tuitionWorkSummary: 'Hungary provides affordable European education with opportunities to work and explore Central Europe.',
    languageProficiencyRequirements: 'IELTS 5.5 or equivalent',
    tuitionCostLow: 3000,
    tuitionCostHigh: 15000,
    workWhileStudyingHours: 20,
    workPermitAfterStudyDuration: '1 year'
  }
};
