import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
});

export const fetchCostOfLivings = () => api.get('/api/cost_of_living');
export const createCostOfLiving = (data) => api.post('/api/cost_of_living', data);
export const updateCostOfLiving = (id, data) => api.put(`/api/cost_of_living/${id}`, data);
export const deleteCostOfLiving = (id) => api.delete(`/api/cost_of_living/${id}`);

const fetchCostOfLiving = async (setCostOfLiving) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/cost_of_living`);
    setCostOfLiving(response.data);
  } catch (error) {
    console.error('Error fetching cost of living:', error);
  }
};

export { fetchCostOfLiving };

const CostOfLiving = () => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    country_id: '',
    title: '',
    description: '',
    image: null,
  });
  const [costOfLiving, setCostOfLiving] = useState([]);
  const [imagePreview, setImagePreview] = useState('');
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    fetchCostOfLiving(setCostOfLiving);
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/locations`);
      setCountries(response.data);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, image: file });
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('country_id', formData.country_id);
    formDataToSend.append('title', formData.title);
    formDataToSend.append('description', formData.description);
    if (formData.image) {
      formDataToSend.append('image', formData.image);
    }

    try {
      if (formData.id) {
        await updateCostOfLiving(formData.id, formData);
      } else {
        await createCostOfLiving(formData);
      }
      setOpen(false);
      alert('Record processed successfully!');
      fetchCostOfLivings(setCostOfLiving);
      handleClose();
    } catch (error) {
      console.error('Failed to submit form:', error);
    }
  };

  const handleEdit = (id) => {
    const entry = costOfLiving.find((entry) => entry.id === id);
    setFormData({
      id: entry.id,
      country_id: entry.country_id,
      title: entry.title,
      description: entry.description,
      image: entry.image,
    });
    fetchCostOfLiving(setCostOfLiving);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this entry?')) {
      try {
        await deleteCostOfLiving(id);
        fetchCostOfLivings(setCostOfLiving);
      } catch (error) {
        console.error('Failed to delete cost of living entry:', error);
      }
    }
  };

  return (
    <Box>
      <Button variant="contained" onClick={handleOpen}>
        Add Cost of Living
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          <Typography variant="h6" gutterBottom>
            Add Cost of Living
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              select
              fullWidth
              label="Country"
              name="country_id"
              value={formData.country_id}
              onChange={handleInputChange}
              SelectProps={{
                native: true,
              }}
              sx={{ mb: 2 }}
            >
              <option value="">Select a country</option>
              {countries.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.location}
                </option>
              ))}
            </TextField>
            <TextField
              fullWidth
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              sx={{ mb: 2 }}
            />
            <TextField
              type="file"
              fullWidth
              label="Image"
              name="image"
              onChange={handleImageChange}
              sx={{ mb: 2 }}
            />
            {imagePreview && (
              <Box sx={{ mb: 2 }}>
                <img src={imagePreview} alt="Preview" style={{ width: '100%', maxHeight: 200 }} />
              </Box>
            )}
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              sx={{ mb: 2 }}
            />
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
      <TableContainer component={Paper} sx={{ mt: 4 }}>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Country Name</TableCell>
        <TableCell>Title</TableCell>
        <TableCell>Description</TableCell>
        <TableCell>Image</TableCell>
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {costOfLiving.map((entry) => (
        <TableRow key={entry.id}>
          <TableCell>{entry.location}</TableCell>
          <TableCell>{entry.title}</TableCell>
          <TableCell>{entry.description}</TableCell>
          <TableCell>
            <img src={`${process.env.REACT_APP_UPLOADS_URL}/${entry.image}`} alt={entry.title} width="100" />
          </TableCell>
          <TableCell>
            <Button onClick={() => handleEdit(entry.id)}>Edit</Button>
            <Button onClick={() => handleDelete(entry.id)}>Delete</Button>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
      </TableContainer>
    </Box>
  );
};

export default CostOfLiving;
